export const environment = {
  GRAPHQL_URL: 'https://pymhyb6d36.execute-api.eu-west-2.amazonaws.com/production/graphql',
  AWS_PROJECT_REGION: 'eu-west-2',
  AWS_COGNITO_REGION: 'eu-west-2',
  AWS_USER_POOLS_ID: 'eu-west-2_Zpjq5S4XI',
  AWS_USER_POOLS_WEB_CLIENT_ID: '55aoq5o29h3a826bupp9hi74is',
  OAUTH_DOMAIN: 'production-radar.auth.eu-west-2.amazoncognito.com',
  AMPLIFY_REDIRECT_SIGN_IN: 'https://radar.voyagu.com/login',
  AMPLIFY_REDIRECT_SIGN_OUT: 'https://radar.voyagu.com/login',
  PORTAL_URL: 'https://q.bcconsolidator.com',
  PORTAL_BUILD_QUERY_KEY: '6a004d54ad43#14',
  TWILIO_SID: 'AP75cd4af0947f16367a9c0871584510c7',
  SEARCH_API: 'https://eba3q2xrqud2t5wv5statubp640ehxct.lambda-url.eu-west-2.on.aws',
  COMMIT_ID: 'a3068b1b5cdb427d4583506f5e7058ce8aa3a44e',
  TRACKING_URL: 'https://tr.businessclassconsolidator.com',
  production: true,
};
